import React from "react";
import {Route, Routes} from 'react-router-dom';
import HomePage from "./pages/HomePage";
import LoadingPage from "./pages/LoadingPage";
import LoginPage from "./pages/LoginPage";

const App = () => {
  return (
    <Routes>
      <Route path="/loading" element={<LoadingPage/>}/>
      <Route path="/home" element={<HomePage/>}/>
      <Route path="/" element={<LoginPage/>}/>
    </Routes>
  );
}

export default App;
