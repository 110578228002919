import React from "react";
import styled from "styled-components";
import Card from "@material-ui/core/Card";

const EvtCard = styled(({ fullscreen, ...props }) => <Card {...props} />)`
  margin: ${({ fullscreen }) => (fullscreen ? "0" : "16px")};
  border-radius: ${({ fullscreen }) => (fullscreen ? "0" : "4px")};
  flex: 1 1 auto;
  overflow-y: scroll;
`;

export default EvtCard;
