import React from "react";
import Column from "../components/Layout/Column";
import {withTheme} from "@emotion/react";
import Header from "../components/Header";
import EventsList from "../components/EventsList";
import { useNavigate } from "react-router-dom";

// import { ProductImages } from "../components/ProductImages";
// import { GTINDescriptionMap } from "../datas/gtinProducts"

import { Box } from "@mui/system";
import Footer from "../components/Footer";
import {useSelector} from "react-redux";


const HomePage = () => {
  const history = useNavigate();
  const apiKey = useSelector(state => state.apiKey);

  return (
    <Column width='100vw'>
      <Header apiKey={apiKey} showGoBack={true} onGoBack={() => history('/')}/>
      <Box height='15px' />
      <EventsList />
      <Footer />
    </Column>
  )

}

export default withTheme(HomePage);
