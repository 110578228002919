import Box from "@mui/material/Box";
import React from "react";
import {withTheme} from "@emotion/react";
import EVTIcon from "../assets/Digimarc.png"

const Footer = () => {
    return (
        <>
            <Box style={{borderBottom: '1px solid black', margin:'25px 0 '}}> </Box>
            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' width='100%' height='100%'>
                <img alt="EVTIcon" src={EVTIcon} style={{width:'30vw', maxWidth:'200px', minWidth: '80px'}} ></img>
            </Box>
        </>
    );
}

export default withTheme(Footer);
