const variants = {
    DEFAULT: 'default',
    INFO : 'info',
    SUCCESS : 'success',
    ERROR : 'error',
    WARNING : 'warning',
};

export const notistackOptions = {
    DEFAULT: {
        variant: variants.DEFAULT,
    },
    INFO : {
        variant: variants.INFO,
    },
    SUCCESS : {
        variant: variants.SUCCESS,
    },
    ERROR : {
        variant: variants.ERROR,
    },
    WARNING : {
        variant: variants.WARNING,
    },
};