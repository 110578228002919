/**
 * (c) Copyright Reserved EVRYTHNG Limited 2022. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

import WatchImg from "../assets/watch.webp"
import ShoesImg from "../assets/shoes.png"
import TshirtImg from "../assets/tshirt.png"
import BagImg from "../assets/bag.png"

export const GTINDescriptionMap = {
  '00860080001300': {productImg: WatchImg},
  '00860080001317': {productImg: ShoesImg},
  '00860080001324': {productImg: TshirtImg},
  '00860080001331': {productImg: BagImg},
};
