/**
 * (c) Copyright Reserved EVRYTHNG Limited 2021. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

/**
 * Get a query param.
 *
 * @param {string} name - Param name to find.
 * @returns {string} Param value if found.
 */
export const getQueryParam = name => new URLSearchParams(window.location.search).get(name);
