import {Box} from "@material-ui/core";
import {Button, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Column from "../components/Layout/Column";
import Row from "../components/Layout/Row";
import {useSnackbar} from "notistack";
import {notistackOptions} from "../utils/notistackUtils";
import styled from "styled-components";
import {connectToSubscriberService} from "../datas/connectToSubService";

import EyeOpenIcon from "../assets/eye-open.png"
import EyeCloseIcon from "../assets/eye-close.png"
import {getQueryParam} from "../utils/utils";
import {useDispatch} from "react-redux";
import {setApiKey} from "../store/action";

const Title = styled.div`
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 39px;
  letter-spacing: 0px;
  text-align: center;
  color: #212121
`

const LoginPage = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const [apiKey, _setApiKey] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    const key = getQueryParam('key');
    if (key) {
      connect(key);
    }
  }, []); // eslint-disable-line

  const connect = (_key) => {
    let key;

    if (_key)
      key = _key;
    else
      key = apiKey;

    try {
      connectToSubscriberService(key);
      console.log(setApiKey(key));
      dispatch(setApiKey(key));
      history('/home');
    } catch (error) {
      enqueueSnackbar(error, notistackOptions.ERROR);
      return;
    }
  }

  return (
    <Column width='100vw' height='100vh' alignItems='center'>

      <Box display='flex' flexDirection='column' alignItems='center' justifyContent='space-around' height='50%'>
        <Box display='flex' flexDirection='column'>
          <Title style={{fontWeight: 700, marginBottom: '8px'}}>GTN</Title>
          <Title style={{marginBottom: '8px'}}>Client</Title>
          <Title>Application</Title>
        </Box>
      </Box>
      <Row>
        <TextField width={'100%'} type={'text'} label="sgIn"/>
        <Box width='64px'/>
      </Row>
      <Box height='15px'/>
      <Row>
        <TextField type={show ? 'text' : 'password'} label="password" value={apiKey}
                   onChange={(event) => _setApiKey(event.target.value)}/>
        <Button variant="outlined" onClick={() => setShow(!show)}>
          {show && <img alt={'show'} src={EyeCloseIcon} width={'20px'}/>}
          {!show && <img alt={'show'} src={EyeOpenIcon} width={'20px'}/>}
        </Button>
      </Row>
      <Box height='15px'/>
      <Button variant="outlined" onClick={() => connect()}> LOGIN </Button>
    </Column>
  )
}

export default LoginPage;
