import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import cross from '../assets/cross.png';
import {Box} from "@material-ui/core";

const PageTitle = styled(({ ...props }) => <Typography {...props} />)`
  flex-grow: 1;
`;

const GoBackIcon = ({onGoBack}) => {
  return (
    <Box style={{padding: '10px'}}>
      <img alt='cross' src={cross} style={{marginTop: '5px', height: '14px'}} onClick={onGoBack}/>
    </Box>
  )
}

/**
 *
 * @param {boolean} showGoBack - set to true to show the cross icon on the right of the header
 * @param {function} onGoBack
 * @returns {JSX.Element}
 * @constructor
 */
export default function Header({apiKey, showGoBack = false, onGoBack}) {
  return (
    <>
      <AppBar style={{backgroundColor: 'white'}} position="static">
        <Toolbar>
            <GoBackIcon onGoBack={onGoBack}/>
          <Box width='100%' display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
            <PageTitle style={{marginLeft: '10px', fontSize: '20px', color: 'black'}}>
                {/* apiKey: {apiKey} */}
                GTN Client Application - {apiKey}
            </PageTitle>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
