/**
 * (c) Copyright Reserved EVRYTHNG Limited 2022. All rights reserved.
 * Use of this material is subject to license.
 * Copying and unauthorised use of this material strictly prohibited.
 */

import { addEPCISEvent } from "../store/action";
import store from "../store/store";

export const connectToSubscriberService = (apiKey) => {
    const ws = new WebSocket("wss://gtn-ws.labs.evrythng.io?apiKey="+apiKey);

    ws.addEventListener('message', (event) => {
        console.log(event.data)
        store.dispatch(addEPCISEvent(JSON.parse(event.data)));
    });

    ws.addEventListener('close', (event) => {
        connectToSubscriberService(apiKey);
    })
}
