import React from "react";
import styled from "styled-components";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CardContent from "@material-ui/core/CardContent";
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import EvtCard from "./Card";

import CloseIcon from '@material-ui/icons/Close';

import {useSelector} from 'react-redux'
import {GTINDescriptionMap} from "../datas/gtinProducts";


const EventsContainer = styled.div`
  margin-top: 20px;
`;

const tabTextStyle = {
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '0px',
  letterSpacing: '4px',
  textAlign: 'left',
};

const Icon = styled.img`
  height: 35px;
  width: 35px;
  margin-top: 5px;
  margin-left: 8px;
`;

const EventNameText = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
  color: #212121;
`;

const Subtitle = styled.div`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
`;

const getGTINofTheEvent = (event) => {
  const dl = event.epcList[0]

  const splitDl = dl.split('/');
  // eslint-disable-next-line
  const indexOf01Identifier = splitDl.findIndex((value, index) => {
    if (value === '01') {
      return index
    }
  })
  return splitDl[indexOf01Identifier + 1];
}

const TabPanel = (props) => {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

const EventDetailsDialog = ({event}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {<Button onClick={handleClickOpen}>
        Details
      </Button>}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
      >
        <AppBar style={{backgroundColor: 'white'}} position="static">
          <Toolbar>
            <IconButton
              edge="start"
              color="default"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon/>
            </IconButton>
            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div" color="textPrimary">
              {event.type}
            </Typography>
          </Toolbar>
        </AppBar>
        <EventDetails event={event}/>
      </Dialog>
    </div>
  );
}

const EventDetails = ({event}) => {
  let nodeId = 0;

  const style = {
    width: '100%',
  };

  return (
    <EvtCard fullscreen>
      <CardContent height='100%' style={{margin: '5px'}}>

        <List sx={style} component="nav" aria-label="mailbox folders">
          {Object.entries(event).map(([key, value]) => (
            <>
              {typeof value !== 'object' &&
                <>
                  <ListItem style={{padding: '0px'}}>
                    <ListItemText key={(key)} primary={key} secondary={typeof value === 'string' ? value : ''}/>
                  </ListItem>
                  <Divider/>
                </>
              }
              {typeof value === 'object' && !Array.isArray(value) &&
                <>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    sx={{height: '100%', padding: '16px 0'}}
                  >
                    <TreeItem key={'' + nodeId++} nodeId={'' + nodeId++} label={key}>
                      {Object.entries(value).map(([k, v]) => (

                        <TreeItem key={'' + nodeId++} nodeId={'' + nodeId++} label={k}>
                          <TreeItem key={'' + nodeId++} nodeId={'' + nodeId++} label={v}/>
                        </TreeItem>

                      ))}
                    </TreeItem>
                  </TreeView>
                  <Divider/>
                </>}

              {Array.isArray(value) && Object.entries(value).map(([i, val]) => (
                <>
                  <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon/>}
                    defaultExpandIcon={<ChevronRightIcon/>}
                    sx={{height: '100%', padding: '16px 0'}}>
                    <TreeItem key={'' + nodeId++} nodeId={'' + nodeId++} label={key}>

                      {typeof val === 'string' && <>
                        <TreeItem key={'' + nodeId++} nodeId={'' + nodeId++} label={val}/>
                      </>}
                      {typeof val === 'object' && Object.entries(val).map(([k2, v2]) => (
                        <TreeItem key={'' + nodeId++} nodeId={'' + nodeId++} label={k2}>
                          <TreeItem key={'' + nodeId++} nodeId={'' + nodeId++} label={v2}/>
                        </TreeItem>
                      ))}
                    </TreeItem>
                  </TreeView>
                  <Divider/>
                </>
              ))}
            </>))
          }
        </List>

      </CardContent>
    </EvtCard>
  )
}

const Event = ({event}) => {

  console.log('GTIN', getGTINofTheEvent(event))
  const formattedTime = new Date(event.eventTime).toString().substring(4, 25)

  return (
    <Box display='flex' flexDirection='row' width='100%' justifyContent='space-between' alignItems='center'
         marginTop='24px'>
      <Box display='flex' flexDirection='row' alignItems='space-between' justifyContent='space-between' width="100vw">
        <Box display='flex' flexDirection='row'>
          <Box alignItems={'center'}>
            <Icon src={GTINDescriptionMap[getGTINofTheEvent(event)].productImg}/>
          </Box>
          <Box display='flex' flexDirection='column' style={{marginLeft: '16px'}}>
            <EventNameText
              style={{marginTop: '-4px'}}>{event.type} - {event.bizStep}
            </EventNameText>
            <Subtitle>{formattedTime}</Subtitle>
          </Box>
          {/* <Button disabled={!moduleThngs} onClick={seeMoreDetails}> */}
          <Box width={'20px'}/>
          <EventDetailsDialog event={event}/>
        </Box>
        <Box style={{marginRight: '24px', display: 'grid'}}>
          {event.epcList.map((epc) => <span key={epc}>{epc}</span>)}
        </Box>
      </Box>
    </Box>

  )
}

export default function EventsList() {
  const [tab, setTab] = React.useState(0);

  const events = useSelector(state => state.epcisEvents)

  console.log('events', events)

  const onTabChange = (event, tab) => {
    setTab(tab);
  };

  return (
    <EventsContainer>
      <Box sx={{borderBottom: 1, borderColor: 'divider', borderBottomStyle: 'solid'}}>
        <Tabs TabIndicatorProps={{
          style: {
            backgroundColor: "#000",
          },
        }}
              indicatorColor='primary'
              value={tab}
              onChange={onTabChange}
              centered>
          <Tab label="EPCIS EVENTS" style={tabTextStyle}/>
        </Tabs>
      </Box>

      <TabPanel value={tab} index={0}>

      </TabPanel>
      {events.length > 0 && events.map((event, index) => {
        return (<Event key={index} event={event}/>)
      })}
    </EventsContainer>
  );
}
